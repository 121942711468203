import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Formik } from "formik"

import AbsenceForm from "../components/unipres/absence"
import BriefMemo from "../components/unipres/brief"
import Button from "../components/button"
import CodeOfConduct from "../components/unipres/codeOfCoduct"
import CriminalPage from "../components/unipres/criminalConviction"
import DrugAndAlcoholForm from "../components/unipres/drugAlcohol"
import Employment from "../components/unipres/employment"
import HRChecklist from "../components/unipres/hrChecklist"
import HolidayPage from "../components/unipres/holiday"
import HumanRights from "../components/unipres/humanRights"
import InductionForm from "../components/unipres/induction"
import Layout from "../components/layout"
import Questionnaire from "../components/unipres/questionnaire"
import ShiftPattern from "../components/unipres/shiftPattern"
import UPUKHealthForm from "../components/unipres/upuk-health"
import WageForm from "../components/unipres/wage"
import Form from "../components/form/FormHelper"
import { FormikCheckbox } from "../components/form/checkbox"
import { FormikInput, Error } from "../components/form"
import { validationSchema, initialValues } from "../components/unipres"
import { FileUpload } from "../components/fileUpload"
import Signature from "../components/Signature"

import { LCard, Col2 } from "../style/containers"
import { FieldSet, HeaderWithLogo } from "../style/containers"
import { submitForm, handleSubmitResponse, FormatFormData, dateCheck} from "../services"
import UnipresLogo from "../assets/images/logos/unipres-logo.jpg"
import PersonalDetailsMinimum from "../components/PersonalDetails/minimum"

const SFormikCheckbox = styled(FormikCheckbox)`
  margin: 2rem 0 0;
`

const IntroductionForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const submit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        personal_details: {
          ...values.personal_details,
          date_of_birth:  
          dateCheck(values.personal_details.date_of_birth)
          }
        }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/unipres")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout emailNoCheck={true}>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <FieldSet>
              <HeaderWithLogo>
                <h1>Unipres Induction</h1>
                <img src={UnipresLogo} alt="Unipres Logo" />
              </HeaderWithLogo>
              <Col2>
                <FormikInput
                  name="consultant_name"
                  type="text"
                  label="Consultant name"
                />
              </Col2>
              <InductionForm />
              <hr />
              <HolidayPage values={values} setFieldValue={setFieldValue} />
              <Questionnaire />
              <UPUKHealthForm />
              <WageForm />
              <AbsenceForm />
              <Employment values={values} setFieldValue={setFieldValue} />
              <HRChecklist />
              <DrugAndAlcoholForm />
              <CodeOfConduct />
              <CriminalPage values={values} setFieldValue={setFieldValue} />
              <BriefMemo />
              <HumanRights />
              <ShiftPattern />
              <Form>
                <SFormikCheckbox
                  name="acceptTraining"
                  label="I agree I have understood the following information from this document"
                />
                <ul>
                  <li>Induction Check List</li>
                  <li>Holiday Declaration</li>
                  <li>UPUK Health, safety and Environmental Information</li>
                  <li>Unipres Absence Procedure</li>
                  <li>Declaration of Past Employment</li>
                  <li>HR Induction Checklist</li>
                  <li>Drug &amp; Alcohol Abuse Statement</li>
                  <li>Code of Conduct</li>
                  <li>Criminal Conviction Declaration</li>
                  <li>Brief Memo</li>
                  <li>
                    Agreement regarding the Retention of Confidential Matters
                  </li>
                  <li>Human Rights Policy</li>
                  <li>Shift Patterns</li>
                </ul>
                <h2>Personal Details</h2>
                <PersonalDetailsMinimum />
                <FileUpload />
                <Signature
                  signatureDrawn={signatureDrawn}
                  setSignatureDrawn={setSignatureDrawn}
                  sigCanvas={sigCanvas}
                />
                {errorText
                  ? errorText.map(e => (
                      <Error style={{ marginTop: "1rem" }} key={e}>
                        {e}
                      </Error>
                    ))
                  : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  name="new-starter"
                >
                  Submit
                </Button>
              </Form>
            </FieldSet>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default IntroductionForm
