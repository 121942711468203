import React from "react"

import { Content } from "../../../style/containers"
import { FormikText } from "../../form/textarea/FormikText"

const Questionnaire = () => {
  return (
    <>
      <h2>Questionnaire</h2>
      <Content>
        <FormikText
          name="question_1"
          label="1. What do you know about Unipres? (Check they know about the company,
                  or have you asked questions about it)"
          placeholder="Enter answer here..."
        />
        <FormikText
          name="question_2"
          placeholder="Enter answer here..."
          label="2. Why did you apply for this position?"
        />
        <FormikText
          name="question_3"
          placeholder="Enter answer here..."
          label="3. Can you tell me about your last position and what your average day
        would entail? (Address any gaps in employment)"
        />
        <FormikText
          name="question_4"
          placeholder="Enter answer here..."
          label="4. What is the most important thing you learnt at previous job?"
        />
        <FormikText
          name="question_5"
          placeholder="Enter answer here..."
          label="5. What would you do if you encountered a problem at work/quality
        concern? How would you approach this?"
        />
        <FormikText
          name="question_6"
          placeholder="Enter answer here..."
          label="6. How do you ensure the work that you do always meets the quality
        standards that have been set?"
        />
        <FormikText
          name="question_7"
          placeholder="Enter answer here..."
          label="7. What qualities and attributes do you think make a good assembly
        operator? Can you provide an example of when you have demonstrated each
        of these in a previous position?"
        />
        <FormikText
          name="question_8"
          placeholder="Enter answer here..."
          label="8. Unipres operate a 3-shift system, days, lates and nights – are you
        able to commit to these hours?"
        />
        <FormikText
          name="question_9"
          placeholder="Enter answer here..."
          label="9. Do you have your own transport? If not, how will you travel to and
        from work?"
        />
        <FormikText
          name="question_10"
          placeholder="Enter answer here..."
          label="10. Your commitment to working overtime is required. There is a minimum
        expectation of one hour every shift (compulsory) and if on day shift,
        both Saturday and Sunday 7.5 hours is required. Do you have any other
        commitments which would prevent you committing you those hours?"
        />
      </Content>
      <p>
        <strong>
          Once this section has been passed to security, a contractor pass will
          be generated for every site user, the pass will remain valid for two
          years from the date below.
        </strong>
      </p>
    </>
  )
}

export default Questionnaire
