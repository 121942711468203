import React from "react"
import styled from "styled-components"

import { Content } from "../../../style/containers"

const Col2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem 0;
  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`

const InductionForm = () => {
  return (
    <>
      <h2>Pin Point Induction Check List</h2>
      <p>
        I can confirm the following points have been explained to me and I
        understand them fully. I commit to adhere to them throughout my
        assignment at Unipres UK:
      </p>
      <Col2>
        <Content>
          <p>Compulsory Over Time</p>
          <p>Shift Patterns – 3 Shift or Continental</p>
          <p>Pay Rates and Over Time Rates</p>
          <p>Time Sheets and Payslips</p>
          <p>
            Uniform and PPE – Acceptable uniform and PPE to be kept in locker on
            site – not to be taken home
          </p>
        </Content>
        <Content>
          <p>Mobile Phone Policy</p>
          <p>Smoking Policy</p>
          <p>Car Park and Security</p>
          <p>Shut down periods/Holidays and Lieu Days</p>
          <p>Absence Policy and Procedure</p>
        </Content>
      </Col2>
      <Content>
        <p>I have read and understood the following documents:</p>
        <ul>
          <li>Pedestrian Access in and through the car park</li>
          <li>Unipres Corporation Code of Ethics</li>
          <li>Behaviour Code of Practice</li>
          <li>Environmental Policy</li>
        </ul>
      </Content>
    </>
  )
}

export default InductionForm
