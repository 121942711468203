import React from "react"
import styled from "styled-components"

import { Content } from "../../../style/containers"
import { FormikText } from "../../form/textarea/FormikText"
import { FormikCheckbox } from "../../form/checkbox"
import { FormikTabs } from "../../form"

const SubText = styled.p`
  text-decoration: underline;
`

const SFormikCheckbox = styled(FormikCheckbox)`
  align-self: flex-start;
  margin: 2rem 0;
`

const HolidayPage = ({ values, setFieldValue }) => {
  return (
    <>
      <h2>Holiday Declaration</h2>
      <Content>
        <SubText>PLEASE CHOOSE FROM ONE OF THE BELOW OPTIONS:</SubText>
        <p>
          Any pre-booked holidays will need to be authorised by Pin-Point
          Recruitment and Unipres UK before you commence employment.
        </p>
        <p>
          Please note any pre-booked holidays may affect your start date with
          Pin-Point Recruitment.
        </p>
      </Content>
      <FormikTabs
        setFieldValue={setFieldValue}
        name="has_holidays"
        label="Do you have pre-booked holidays?"
        tabs={[
          { name: "1", label: "Yes" },
          { name: "0", label: "No" },
        ]}
      />
      {values.has_holidays === "1" ? (
        <Content>
          <FormikText
            name="holiday_dates"
            label="Holiday Dates"
            placeholder="Enter dates here.."
          />
          <FormikText
            name="holiday_duration"
            label="Duration"
            placeholder="Enter duration here.."
          />
        </Content>
      ) : null}
    </>
  )
}

export default HolidayPage
