import React from "react"

import { Content } from "../../../style/containers"

const CodeOfConduct = () => {
  return (
    <>
      <h2>Unipres Group - Code of Conduct</h2>
      <Content>
        <p>
          The executives and employees of the Unipres Group not only adhere to
          laws and regulations and social ethics but also observe the Unipres
          Group Code of Conduct in the implementation of all corporate
          activities for the realization of the Unipres management philosophy.
        </p>
        <p>
          When executives or employees of the Unipres Group become aware of any
          behaviour that infringes the Unipres Group Code of Conduct, they
          report it.
        </p>
        <ol>
          <li>
            <strong>Our Stance toward Our Customers</strong>
            <ol>
              <li>
                <p>Safe Provision of Excellent Products</p>
                <p>
                  We provide products that offer high quality at a reasonable
                  price by taking the position of consumers and users and giving
                  full consideration to safety. We are engaged in ongoing
                  creative and innovative technological development and actively
                  cultivate new fields of business.
                </p>
              </li>
              <li>
                <p>Response to Product-related Accidents and Trouble</p>
                <p>
                  As a company that has a responsibility to society, we promptly
                  report to management executives and respond quickly if a
                  product defect arises.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Our Stance toward Our Business Partners</strong>
            <ol>
              <li>
                <p>Conduct of Fair Transactions</p>
                <p>
                  We build and maintain equal, fair, and transparent trade
                  relationships with all our business partners and build strong
                  cooperative ties through mutual understanding and respect as
                  business partners. In this way, we seek our mutual benefit.
                </p>
              </li>
              <li>
                <p>Observance of the Competition Law and Related Legislation</p>
                <p>
                  We observe the Competition Law and related legislation, and
                  conduct all our transactions with integrity, fairness, and
                  transparency.
                </p>
              </li>
              <li>
                <p>Relations with Interested Parties and Business Partners</p>
                <p>
                  In our relations with interested parties and business
                  partners, we neither accept nor engage in profit-sharing
                  activities or gift exchange beyond the scope of social
                  courtesy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Our Stance toward Our Stockholders and Investors</strong>
            <ol>
              <li>
                <p>Appropriate and Prompt Provision of Information</p>
                <p>
                  We disclose in a timely and proper fashion information needed
                  by the company’s shareholders and other stakeholders and thus
                  aim to ensure accurate understanding and evaluation of our
                  company. Aware of our social responsibility, we engage in fair
                  market competition.
                </p>
              </li>
              <li>
                <p>Prohibition of Insider Trading</p>
                <p>
                  We do not engage in transactions deemed to be, or leading to
                  suspicion of, insider trading.
                </p>
              </li>
              <li>
                <p>Proper Accounting</p>
                <p>
                  We observe laws and internal company regulations and engage in
                  proper accounting to secure accuracy and credibility in our
                  financial and tax accounting. By strengthening internal and
                  external audit functions, we aim to engage in transparent
                  corporate management.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Our Stance toward Society</strong>
            <ol>
              <li>
                <p>Our Stance toward Society</p>
                <p>
                  Based on Umpires’ environment policy we develop, maintain, and
                  continuously improve a system that enables us to observe
                  environment-related regulations. In product development, we
                  ensure product safety as well as promote resource- and
                  energy-saving and the reduction of hazardous substances in
                  order to minimize the environmental impact.
                </p>
              </li>
              <li>
                <p>Social Contribution and Integration into the Community</p>
                <p>
                  As a member of society, we make an active and ongoing
                  contribution to society. We are actively involved in the
                  communities and contribute to the development of our business
                  activities and these communities.
                </p>
              </li>
              <li>
                <p>Conduct as a World Citizen</p>
                <p>
                  We follow international rules and the laws of individual
                  nations and implement management that respects culture and
                  customs of each nation and contributes to their development.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Our Workplace</strong>
            <ol>
              <li>
                <p>Workplace Safety</p>
                <p>
                  We endeavor to provide a safe and healthy work environment,
                  and we perform the necessary measures to ensure that our
                  buildings, facilities, and equipment conform to health and
                  safety regulations.
                </p>
              </li>
              <li>
                <p>Labour Environment</p>
                <p>
                  By not engaging in the employment of children, forced labor or
                  human trafficking and ensuring appropriate working hours and
                  payment of wages, etc., we take measures to observe the
                  employment regulations of each nation.
                </p>
              </li>
              <li>
                <p>Workplace Environment</p>
                <p>
                  We each respect the opinions of others and aim to develop an
                  environment in which we can take on new challenges without
                  fear of failure.
                </p>
              </li>
              <li>
                <p>Respect for the Individual</p>
                <p>
                  We respect the human rights of all workers regardless of their
                  race, nationality, gender, sexual orientation, religion,
                  disability, age, social origin, or employment status and
                  strive to eliminate discrimination and actions which harm the
                  dignity of the individual. We do not engage in harassment of
                  any kind.
                </p>
                <p>
                  With a spirit of openness and trust, we aim to promote the
                  creation of workplaces where it is easy for all people to work
                  together.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Our Work Mindset</strong>
            <ol>
              <li>
                <p>Attitude toward Work and Work Duties</p>
                <p>
                  We engage in our work with a sense of duty and commitment. We
                  perform every task to the best of our ability and seek to
                  continuously improve our performance.
                </p>
              </li>
              <li>
                <p>Protection of Company Property</p>
                <p>
                  We properly control and protect our company’s rights and
                  property, both tangible and intangible. We do not engage in
                  personal or other unauthorized use of them. We respect other
                  people’s rights and property.
                </p>
              </li>
              <li>
                <p>Proper Control of Information</p>
                <p>
                  In accordance with laws and internal company regulations, we
                  properly control information held by the company and our
                  business partners.
                </p>
              </li>
              <li>
                <p>Relations with Government and Public Administration</p>
                <p>
                  In our business relations with public officials, we strive to
                  build transparent and fair relationships and do not engage in
                  the exchange of money or gifts beyond the scope of social
                  custom.
                </p>
              </li>
              <li>
                <p>Separation of Our Professional and Private Lives</p>
                <p>
                  We separate our professional and private lives. We do not use
                  the company name for personal gain.{" "}
                </p>
                <p>
                  We do not engage in such actions as taking company resources
                  for personal use or personally receiving money or gifts from
                  business partners or other similar parties
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>August 21, 2017</p>
      </Content>
    </>
  )
}

export default CodeOfConduct
