import React from "react"
import { FormikDatepicker, FormikInput } from "../form"
import { Col2 } from "../../style/containers"
import { TagSelector } from "../form/tags"
import { dateOfBirthCheck } from "../../services"

const PersonalDetailsMinimum = () => {
  return (
    <>
      <Col2>
        <FormikInput
          name="personal_details.first_name"
          type="text"
          label="First name"
        />
        <FormikInput
          name="personal_details.surname"
          type="text"
          label="Surname"
        />
        <FormikDatepicker
          name="personal_details.date_of_birth"
          label="Date of Birth"
          placeholderText="Select a date"
          maxDate={dateOfBirthCheck()}
        />
        <FormikInput name="personal_details.email" type="email" label="Email" />
        <FormikInput name="personal_details.mobile" type="tel" label="Mobile" />
        <FormikInput
          label="Landline"
          name="personal_details.landline"
          type="tel"
          optional={true}
        />
        <FormikInput
          type="text"
          label="Address line 1 - house number and street name"
          name="personal_details.address_1"
        />
        <FormikInput
          type="text"
          label="Address line 2"
          name="personal_details.address_2"
        />
        <FormikInput
          type="text"
          label="Town/City"
          name="personal_details.address_town"
        />
        <FormikInput
          type="text"
          label="County"
          name="personal_details.address_3"
        />
        <FormikInput
          type="text"
          label="Country"
          name="personal_details.country"
        />
        <FormikInput
          name="personal_details.postcode"
          type="text"
          label="Postcode"
        />
        <TagSelector
          label="What are your current methods of transport?"
          name="method_of_transport"
          options={["Bike", "Bus", "Car", "Walk", "Other"]}
        />
      </Col2>
    </>
  )
}

export default PersonalDetailsMinimum
