import React from "react"
import styled from "styled-components"

import { FormikInput, FormikTabs } from "../../../components/form"
import { Content } from "../../../style/containers"

const SubText = styled.p`
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 2rem;
`

const InputWrapper = styled.div`
  max-width: 300px;
`

const CriminalPage = ({ values, setFieldValue }) => {
  return (
    <>
      <h2>Criminal Conviction Declaration</h2>
      <Content>
        <SubText>
          The information you provide is NOT a short-listing tool in the
          recruitment process.
        </SubText>
        <FormikTabs
          setFieldValue={setFieldValue}
          name="previous_convictions"
          label="Do you have an unspent conviction?"
          tabs={[
            { name: "1", label: "Yes / Don't know" },
            { name: "0", label: "No" },
          ]}
        />
        {values.previous_convictions === "1" ? (
          <>
            <p>
              If yes or don’t know, please supply a contact number and you will
              be contacted by a member of staff who will discuss your conviction
              in more detail.
            </p>
            <InputWrapper>
              <FormikInput
                name="previous_convictions_phone"
                type="text"
                label="Contact number"
              />
            </InputWrapper>
          </>
        ) : null}
        <h2>DECLARATION</h2>
        <p>
          I confirm that the information provided on this form is both truthful
          and accurate. I have omitted no facts that could affect my future
          employment. I consent to any sensitive or personal data, disclosed as
          part of my application, being used in connection with the search for
          work, subject to the relevant Data Protection legislation.
        </p>
        <p>
          If offered employment at the end of this training course, I will
          inform my new employer immediately of any circumstances that may
          affect my work, such as changes to health, subsequent pending
          prosecutions or convictions, which may arise whilst I am registered
          for permanent or temporary work with them.
        </p>
        <p>
          I understand that a failure to declare an unspent conviction can
          affect my future employment
        </p>
        <p>
          All information provided will be treated in the strictest confidence
          and will be processed and held in accordance with relevant Data
          Protection Legislation.
        </p>
      </Content>
    </>
  )
}

export default CriminalPage
