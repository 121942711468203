import React from "react"
import styled from "styled-components"

import { FormikCheckbox } from "../../form/checkbox/index"

const Col2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const SFormikCheckbox = styled(FormikCheckbox)`
  margin-bottom: 1rem;
`

const HRChecklist = () => {
  return (
    <>
      <h2>HR Induction Checklist</h2>
      <Col2>
        <p>
          I Confirm I have received and understood the following information
          during my induction from:
        </p>
        <div>
          <SFormikCheckbox name="check_1" label="1. Quality Policy" />
          <SFormikCheckbox
            name="check_2"
            label="2. Union Membership (arrange with rep)."
          />
          <SFormikCheckbox
            name="check_3"
            label="3. Company mission statement"
          />
          <SFormikCheckbox
            name="check_4"
            label="4. Term &amp; Conditions of Employment (Inc. code of conduct)"
          />
          <SFormikCheckbox
            name="check_5"
            label="5. Corporate code of conduct"
          />
          <SFormikCheckbox name="check_6" label="6 GPP Scheme" />
          <SFormikCheckbox
            name="check_7"
            label="7. Company Private Health Scheme"
          />
          <SFormikCheckbox
            name="check_8"
            label="8. Motor &amp; Allied Trade Benevolent Fund"
          />
          <SFormikCheckbox name="check_9" label="9 UPUK Organisation" />
          <SFormikCheckbox
            name="check_10"
            label="10. Eligibility to work in the UK"
          />
          <SFormikCheckbox
            name="check_11"
            label="11. Working Time Regulations"
          />
          <SFormikCheckbox
            name="check_12"
            label="12. Private Disclosure Policy"
          />
          <SFormikCheckbox
            name="check_13"
            label="13. Company Legislation in Relation to Confidential Matters"
          />
          <SFormikCheckbox
            name="check_14"
            label="14. Corporate Policy for Human Rights"
          />
        </div>
      </Col2>
    </>
  )
}

export default HRChecklist
