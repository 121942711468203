import React from "react"

import { Content } from "../../../style/containers"
import { FormikInput } from "../../../components/form"

const HumanRights = () => {
  return (
    <>
      <h2>Basic Policy for Human Rights</h2>
      <Content>
        <p>
          The Unipres group stipulate its policy of respecting diversity and
          human rights in "Unipres Group Code of Conduct Guide Book", and this
          Basic Policy for Human Rights ("the Policy") is developed based on the
          International Bill of Rights (the Universal Declaration of Human
          Rights and the two International Covenants), the International Labor
          Organization Declaration on Fundamental Principles and Rights at Work,
          and the United Nations Guiding Principles on Business and Human
          Rights.
        </p>
        <p>
          As our firm commitment to respect human rights is comprehensively
          defined in the Policy, we will meet our responsibility to respect
          human rights in line with the Policy, together with our existing
          policies and processes.
        </p>
        <p>
          The policy applies to all the executives and employees of Unipres
          group.
        </p>
        <ol>
          <li>
            <p>
              <strong>Human Rights and Privacy</strong>
            </p>
            <p>We respect the human rights and privacy of the individual.</p>
          </li>
          <li>
            <p>
              <strong>Discrimination and Harassment</strong>
            </p>
            <p>
              We do not engage in discrimination or harassment of any kind based
              on race, nationality, gender, sexual orientation, religion,
              disability, age, social origin, or employment status.
            </p>
          </li>
          <li>
            <p>
              <strong>Child Labor, Forced Labor, and Human Trafficking</strong>
            </p>
            <p>
              We do not allow child labor, forced labor, and human trafficking.
            </p>
          </li>
          <li>
            <p>
              <strong>Remuneration</strong>
            </p>
            <p>
              We comply with the laws of each country and region regarding the
              minimum wages, overtime, wage deductions, performance-based pay,
              and other remuneration
            </p>
          </li>
          <li>
            <p>
              <strong>Working Hours</strong>
            </p>
            <p>
              We comply with the laws of each country and region regarding the
              setting of employees' working hours including overtime and the
              granting of scheduled days off and paid annual vacation time.
            </p>
          </li>
          <li>
            <p>
              <strong>Dialogue and Consultation with Employees</strong>
            </p>
            <p>
              Taking into account the laws and labor practices of each country,
              the Company will try to foster a good relationship with its
              employees and to resolve issues by having a sincere and
              constructive dialogue.
            </p>
          </li>
          <li>
            <p>
              <strong>Safe and Healthy Working Environment</strong>
            </p>
            <p>
              We give the top priority to the health and safety of employees and
              make every effort to prevent accidents.
            </p>
          </li>
          <li>
            <p>
              <strong>Responsibilities to Respect Human Rights</strong>
            </p>
            <p>
              Where we confirm that we have caused or contributed to adverse
              human righ impacts, the company will provide for or cooperate in
              legitimate processes to provide remediation.
            </p>
          </li>
          <li>
            <p>
              <strong>Stakeholders and Human Rights</strong>
            </p>
            <p>
              We seek the respect of human rights from all parties we do
              business with and related parties.
            </p>
          </li>
          <li>
            <p>
              <strong>Promotion of Human Rights Policy</strong>
            </p>
            <p>
              We cultivate a corporate culture of respecting human rights by
              conducting human rights enlightenment educations and trainings to
              all Unipres group executives and employees.
            </p>
          </li>
        </ol>
        <p>
          President and Representative Director
          <br />
          Masanobu Yoshizawa
        </p>
        <br />
        <p>November 1st, 2018</p>
      </Content>
    </>
  )
}

export default HumanRights
