import React from "react"

import { Content } from "../../../style/containers"

const BriefMemo = () => {
  return (
    <>
      <h2>Briefing Memo</h2>
      <Content>
        <p>To: All Staff</p>
        <p>Subject: Mobile Smart Phones at NMUK</p>
        <p>Date: 10/08/2017</p>

        <br />
        <p>Dear Colleague</p>

        <p>
          Following a directive this morning by UPJ any UPUK staff member
          attending NMUK’s site will not be allowed to carry with them a mobile
          phone with camera functionality. We are awaiting further clarification
          on the situation, but this will be enforced with immediate effect.
        </p>
        <p>
          Staff are requested to sign a compliance acknowledgement and
          additional controls will be applied by departments who attend on an
          ad-hoc basis.
        </p>
        <p>
          Can all Managers please ensure staff sign to confirm the above and
          maintain records accordingly.
        </p>
        <p>Regards</p>
        <br />
        <p>Tony Adams GM.</p>
      </Content>
      <Content>
        <hr />
        <p>
          <strong>
            Agreement regarding the Retention of Confidential Matters
          </strong>
        </p>
        <p>
          I hereby pledge to obey the following matters when I engaged in
          business for the above company.
        </p>
        <p>
          <strong>Article 1 - </strong> (Confidentiality during employment)
        </p>
        <p>
          I promise to follow the “Guidelines for the Handling of Portable
          Information Terminal with Camera and Photographic Apparatus” and
          “Social Media Guideline”, and to not illegally disclose or use the
          following confidential information of the above company without their
          permission, based on the stipulations in the “Unipres Group Code of
          Conduct”.
        </p>
        <ol>
          <li>Confidential matters regarding management</li>
          <li>Confidential matters regarding deals</li>
          <li>Confidential matters regarding technology</li>
        </ol>
        <p>
          <strong>Article 2 - </strong>
          (Confidentiality after leaving the organisation)
        </p>
        <p>
          I promise not to illegally disclose or use the confidential
          information of each section of the previous article, even after
          leaving my employment.
        </p>
        <p>
          <strong>Article 3 - </strong> (Compensation for damage)
        </p>
        <p>
          In the event that any section of the previous article and any section
          of article 1 are breached, and confidential information is illegally
          disclosed, leaked or used; I recognize that I bear the responsibility
          by law, and agree to compensate all damages incurred by the above
          company due to breach of the agreement.
        </p>
        <p>
          <strong>Article 4 - </strong> (Confidential information of third
          parties)
        </p>
        <p>
          In engaging in business of the above company, for all confidential
          information possessed by third parties, I promise not to disclose, use
          or apply (hereinafter referred to as “use etc.”) such information to
          the above company without prior written permission of the
          corresponding third party; or to tempt the above company to use etc.,
          or to cause the above company to take actions which make the above
          company appear to use etc. such information.
        </p>
        <p>
          <strong>Article 5 - </strong> (Compliance with confidentiality
          obligation involving third parties)
        </p>
        <p>
          Before being employed by the above company, in case that I am under a
          confidentially obligation or competition prohibition by third parties,
          I promise to inform my superior of such obligations every time it is
          necessary, and to adhere to the corresponding confidentially
          obligation and competition prohibition.
        </p>
      </Content>
    </>
  )
}

export default BriefMemo
