import React from "react"
import styled from "styled-components"

import { Content } from "../../../style/containers"

const Table = styled.table`
  width: 100%;
  margin: 0;
  border-collapse: collapse;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
`

const Str = styled.tr`
  border-bottom: 1px solid #ccc;
`

const Std = styled.td`
  border: none;
  border-right: 1px solid #ccc;
  padding: 10px;
  text-align: start;
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  border-top: 1px solid #ccc;
  padding: 2rem 0 1rem;
  margin-bottom: 1rem;
  ${p => p.theme.layout.web} {
    grid-template-columns: 30% auto;
  }
`

const Third = styled.div`
  width: 100%;
`

const TwoThird = styled.div``

const WageForm = () => {
  return (
    <>
      <Content>
        <p>
          Wage: <strong>Paid Weekly</strong>
        </p>
        <p>
          Bonus: <strong>No Bonus</strong>
        </p>
        <p>Start Date:</p>
        <p>
          Start Time &amp; Finish Time: <strong>Across 24 hours</strong>
        </p>
        <p>
          Report to: <strong>Rob Thompson</strong>
        </p>
        <p>
          Contract Length: <strong>Temporary</strong>
        </p>
      </Content>
      <div style={{ marginBottom: "2rem" }}>
        <ZeroTo12WeeksTable />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <TwelveWeeksPlusTable />
      </div>
      <p>
        Calendar weeks already accrued towards the qualifying period for the
        purpose of the regulations 7 and 8 of the Agency Worker Regulations
        2010: ------------------------
      </p>
      <Box>
        <Third>
          <p>
            <strong>Training:</strong>
          </p>
        </Third>
        <TwoThird>
          <p>
            1-week full training and induction for each candidate under the
            guidance of Qualified Unipres trainers. The candidate will be made
            aware of Health and Safety in the work place, a guided tour of the
            site and basic training.
          </p>
        </TwoThird>
        <Third>
          <p>
            <strong>Qualifications:</strong>
          </p>
        </Third>
        <TwoThird>
          <p>N/A</p>
        </TwoThird>
        <Third>
          <p>
            <strong>Job Spec:</strong>
          </p>
        </Third>
        <TwoThird>
          <p>
            Duties include operating machinery and working within a busy
            production line. You are responsible for own quality checking. You
            will be trained on at least 3 cells within the factory. Assembling
            parts using small hand tools and machinery, working towards strict
            deadlines and set targets.
          </p>
        </TwoThird>
        <Third>
          <p>
            <strong>Notice Period:</strong>
          </p>
        </Third>
        <TwoThird>
          <p>1 week</p>
        </TwoThird>
      </Box>
      <Content>
        <p>
          Hirers Collective Facilities to the Agency Worker
          <br />
          Use of the staff on site car park and canteen
        </p>
        <p>
          <strong>Health &amp; Safety</strong> 1-hour Health and Safety training
          and induction. This included awareness of heavy lifting and dangers
          around the work place. Health and Safety training carried out a
          qualified trainer.
        </p>
        <p>
          <strong>PPE Requirement</strong> Safety boots, Safety glasses,
          Armlets, Ear Plugs
        </p>
        <p>
          <strong>Contact Details</strong> Anna Moskal (07734) 549 508 or Tom
          Horrocks (07971) 686 750
        </p>
      </Content>
    </>
  )
}

export default WageForm

function ZeroTo12WeeksTable() {
  return (
    <>
      <p>
        <strong>
          0 to 12 Weeks Rates - Production - Assembly, Press shop, Materials
        </strong>
      </p>
      <Table>
        <tbody>
          <Str>
            <Std as="th">D/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>9.55</Std>
            <Std>14.33</Std>
            <Std>19.10</Std>
          </Str>
          <Str>
            <Std as="th">L/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>10.30</Std>
            <Std>15.08</Std>
            <Std>19.85</Std>
          </Str>
          <Str>
            <Std as="th">N/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>11.05</Std>
            <Std>15.83</Std>
            <Std>20.60</Std>
          </Str>
        </tbody>
      </Table>
    </>
  )
}

function TwelveWeeksPlusTable() {
  return (
    <>
      <p>
        <strong>
          12 Weeks + Rates - Production - Assembly, Press shop, Materials
        </strong>
      </p>
      <Table>
        <tbody>
          <Str>
            <Std as="th">D/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>9.68</Std>
            <Std>14.52</Std>
            <Std>19.36</Std>
          </Str>
          <Str>
            <Std as="th">L/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>10.43</Std>
            <Std>15.27</Std>
            <Std>20.11</Std>
          </Str>
          <Str>
            <Std as="th">N/S</Std>
            <Std as="th">Basic rate</Std>
            <Std as="th">OT1</Std>
            <Std as="th">OT2</Std>
          </Str>
          <Str>
            <Std>Payrate</Std>
            <Std>11.18</Std>
            <Std>16.02</Std>
            <Std>20.86</Std>
          </Str>
        </tbody>
      </Table>
    </>
  )
}
