import React from "react"

import { Content } from "../../../style/containers"

const DrugAndAlcoholForm = () => {
  return (
    <>
      <h2>Drug &amp; Alcohol Abuse Statement</h2>
      <Content>
        <p>
          When applying for Unipres you <strong>WILL BE</strong> subject to a
          drug test.
        </p>
        <p>The result of this may affect your future job prospects:</p>
        <ol>
          <li>
            <p>
              In line with the Company's disciplinary rules, the following will
              be regarded as serious misconduct
            </p>
            <ul>
              <li>
                Failure to inform the company of any potential problems arising
                from a schedule drug and alcohol test.
              </li>
              <li>
                Attending work and/or carrying out duties under the influence of
                alcohol or drugs.
              </li>
              <li>
                Consumption of alcohol or drugs whilst on duty (other than where
                prescribed or approval has been given).
              </li>
            </ul>
          </li>
        </ol>
        <p>
          A direct breach of these rules will result in job position not being
          offered or dismissal in line with company Human Resource Policy.
        </p>
        <p>
          Where a breach of these rules occurs, but it is established that a
          declared existing alcohol or drug abuse related problem exists, and
          the member of staff is willing to co-operate in referral to an
          appropriate service and subsequent treatment, the Company will make
          appropriate decisions based on this.{" "}
        </p>
      </Content>
    </>
  )
}

export default DrugAndAlcoholForm
