import React from "react"
import styled from "styled-components"

import { Content } from "../../../style/containers"
import { FormikInput } from "../../../components/form"

const Col2 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const UPUKHealthForm = () => {
  return (
    <>
      <h2>UPUK Health, safety and Environmental Information</h2>
      <Content>
        <p>
          Having read and understood this UPUK Health, safety and Environmental
          Information for Contractors document, I agree to abide by these rules.
        </p>
        <p>
          I accept that if I fail to comply with its requirements I will removed
          from site.
        </p>
        <h3>Employee Details</h3>
        <p>
          Name of Organisation: <strong>Pin Point Recruitment</strong>
        </p>

        <p>
          Name of Supervisor: <strong>Anna Moskal</strong>
        </p>

        <p>
          Name of UPUK Contact: <strong>Gary McVeigh</strong>
        </p>

        <p>
          Site Telephone Number: <strong>0191 261 5205</strong>
        </p>
        <p>
          Having read and understood this UPUK Health and Safety and
          Environmental contractor’s document I have ensured that all my
          employees working on site have received copies and agree that all
          representatives of my organisation will comply with its requirements.
        </p>
        <p>
          I also agree to remove any employee from organisation off site if they
          Fail to comply with its requirements.
        </p>
      </Content>
      <Col2>
        <FormikInput
          name="transport_method"
          type="text"
          label="Transport method"
        />
        <FormikInput
          name="transport_model_make"
          type="text"
          label="Model and Make"
        />
        <FormikInput name="transport_colour" type="text" label="Colour" />
        <FormikInput
          name="transport_registration_number"
          type="text"
          label="Vehicle Registration Number"
        />
      </Col2>
      <Content>
        <p>
          <strong>Confirmation of Temporary Assignment</strong>
        </p>
        <p>
          This is to confirm details of your assignment. You will be working on
          a contract of services via Pin Point Recruitment at Unipres on a
          temporary contract.
        </p>
        <hr />
      </Content>
    </>
  )
}

export default UPUKHealthForm
