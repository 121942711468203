import * as Yup from "yup"
import { dateRegex, phoneRegex, standardRequiredText } from "../../constants"
import { parseDateString } from "../../utils/form-data"
import moment from "moment"

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  personal_details: Yup.object().shape({
    first_name: Yup.string().required(standardRequiredText),
    surname: Yup.string().required(standardRequiredText),
    date_of_birth: Yup.date()
      .transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      )
      .required(standardRequiredText),
    email: Yup.string()
      .email(
        "Must be a valid email address in the format 'example@example.com'"
      )
      .required(standardRequiredText),
    mobile: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
    address_1: Yup.string().required(standardRequiredText),
    address_2: Yup.string(),
    address_3: Yup.string(),
    postcode: Yup.string().required(standardRequiredText),
    address_town: Yup.string().required(standardRequiredText),
    country: Yup.string().required(standardRequiredText),
  }),
  acceptTraining: Yup.boolean().oneOf(
    [true],
    "You must confirm you have understood the topics before continuing"
  ),
  has_holidays: Yup.boolean().required("Answer is required"),
  holiday_dates: Yup.string(),
  holiday_duration: Yup.string(),
  question_1: Yup.string().required("Answer is required"),
  question_2: Yup.string().required("Answer is required"),
  question_3: Yup.string().required("Answer is required"),
  question_4: Yup.string().required("Answer is required"),
  question_5: Yup.string().required("Answer is required"),
  question_6: Yup.string().required("Answer is required"),
  question_7: Yup.string().required("Answer is required"),
  question_8: Yup.string().required("Answer is required"),
  question_9: Yup.string().required("Answer is required"),
  question_10: Yup.string().required("Answer is required"),
  check_1: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_2: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_3: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_4: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_5: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_6: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_7: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_8: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_9: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_10: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_11: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_12: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_13: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  check_14: Yup.boolean().oneOf(
    [true],
    "You must confirm you understand the following information"
  ),
  transport_method: Yup.string().required("Transport method is required"),
  transport_model_make: Yup.string().required(
    "Transport make/modal is required"
  ),
  transport_colour: Yup.string().required("Transport colour is required"),
  transport_registration_number: Yup.string().required(
    "Transport registration is required"
  ),
  new_employee: Yup.boolean(),
  previous_department: Yup.string(),
  previous_supervisor: Yup.string(),
  previous_employment_dates: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  previous_convictions: Yup.boolean().required("Answer is required"),
  previous_convictions_phone: Yup.string().matches(
    phoneRegex,
    "Must be a valid phone number"
  ),
  documents: Yup.array()
    .max(2, "Maximum of 2 documents")
    .required(standardRequiredText),
})
