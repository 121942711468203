import React from "react"
import styled from "styled-components"

import { FormikInput, FormikTabs } from "../../../components/form"
import { Content } from "../../../style/containers"
import { FormikCheckbox } from "../../form/checkbox"

const SFormikCheckbox = styled(FormikCheckbox)`
  margin: 2rem;
`

const Col2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const Employment = ({ values, setFieldValue }) => {
  return (
    <>
      <h2>Declaration of Past Employment with Unipres UK Ltd.</h2>
      <Content>
        <p>
          <strong>Please complete the relevant section of this page:</strong>
        </p>
        <FormikTabs
          setFieldValue={setFieldValue}
          name="new_employee"
          label="Have you worked for Unipres in the past?"
          tabs={[
            { name: "1", label: "Yes" },
            { name: "0", label: "No" },
          ]}
        />
        {values.new_employee === "1" ? (
          <Col2>
            <FormikInput
              name="previous_department"
              type="text"
              label="Department"
            />
            <FormikInput
              name="previous_supervisor"
              type="text"
              label="Supervisor"
            />
            <FormikInput
              name="previous_employment_dates"
              type="text"
              label="Dates"
            />
          </Col2>
        ) : null}
        <p>
          Any misinformation presented on this document may result in your
          dismissal from the contract.
        </p>
      </Content>
    </>
  )
}

export default Employment
