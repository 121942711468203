import React from "react"
import styled from "styled-components"

import { Content } from "../../../style/containers"

const Table = styled.table`
  width: fit-content;
  margin: 2rem auto;
  border-collapse: collapse;
  text-align: center;
`

const Str = styled.tr``

const Std = styled.td`
  border: 1px solid #ccc;
  border-bottom: ${p => (p.noBottom ? "none" : "1px solid #ccc")};
  padding: 10px 30px;
`

const Underline = styled.span`
  text-decoration: underline;
  text-align: center;
  font-weight: 600;
`

const AbsenceForm = () => {
  return (
    <>
      <h2>Unipres Absence Procedure</h2>
      <Content>
        <p>
          The absence procedure outlined below <strong>MUST</strong> be followed
          by all Pin Point employees:
        </p>
        <p>
          If you are unable to attend Unipres for your shift, you must call
          either: -
        </p>
        <Content>
          <Table>
            <tbody>
              <Str>
                <Std>Alice Palmer</Std>
                <Std>Tom Horrocks</Std>
              </Str>
              <Str>
                <Std>(07874) 131 046</Std>
                <Std>(07971) 686 750</Std>
              </Str>
              <Str>
                <Std>0191 261 5205</Std>
                <Std>0191 261 5205</Std>
              </Str>
            </tbody>
          </Table>
        </Content>
      </Content>
      <p>
        <Underline>AND</Underline>
      </p>
      <p>
        <strong>Unipres</strong> on (0191) 418 2000 and speak to your
        supervisor.
      </p>
      <p>
        You MUST call at least 20 minutes prior to the start of your shift and
        explain the reasons for your absence and when you will return to work.
        If you are off for more than one day you are required call Pin Point
        Recruitment and Unipres every day.
      </p>
      <p>
        This is standard absence procedure and <Underline>MUST</Underline>{" "}
        always be adhered to. Failure to do so may jeopardise your position at
        Unipres.
      </p>
    </>
  )
}

export default AbsenceForm
