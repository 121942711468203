export const initialValues = {
  consultant_name: "",
  personal_details: {
    first_name: "",
    surname: "",
    date_of_birth: "",
    email: "",
    mobile: "",
    landline: "",
    address_1: "",
    address_2: "",
    address_3: "",
    postcode: "",
    address_town: "",
    country: "",
  },
  acceptTraining: 0,
  has_holidays: 0,
  holiday_dates: "",
  holiday_duration: "",
  question_1: "",
  question_2: "",
  question_3: "",
  question_4: "",
  question_5: "",
  question_6: "",
  question_7: "",
  question_8: "",
  question_9: "",
  question_10: "",
  check_1: 0,
  check_2: 0,
  check_3: 0,
  check_4: 0,
  check_5: 0,
  check_6: 0,
  check_7: 0,
  check_8: 0,
  check_9: 0,
  check_10: 0,
  check_11: 0,
  check_12: 0,
  check_13: 0,
  check_14: 0,
  transport_method: "",
  transport_model_make: "",
  transport_colour: "",
  transport_registration_number: "",
  new_employee: 0,
  previous_department: "",
  previous_supervisor: "",
  previous_employment_dates: "",
  previous_convictions: 0,
  previous_convictions_phone: "",
  documents: [],
}
