import React from "react"
import styled from "styled-components"

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 2rem 0;
`

const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Table = styled.table`
  width: fit-content;
  margin: ${p => (p.marginBottom ? "0 0 2rem" : "0")};
  border-collapse: collapse;
`

const Str = styled.tr``

const Std = styled.td`
  border: 1px solid #ccc;
  border-bottom: ${p => (p.noBottom ? "none" : "1px solid #ccc")};
  padding: 10px;
  text-align: center;
`

const Sth = styled.th`
  border: 1px solid #ccc;
  padding: 10px;
`

const ShiftPattern = () => {
  return (
    <>
      <h2>Unipres Shift Patterns</h2>
      <TableContainer>
        <h3>2 Shift Pattern</h3>
        <GridRow>
          <div>
            <h4>Day Shift - Monday to Thursday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>07:00</Std>
                  <Std>09:35</Std>
                  <Std>12:20</Std>
                  <Std>15:18</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
          <div>
            <h4>Day Shift - Friday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>07:00</Std>
                  <Std>09:20</Std>
                  <Std>12:05</Std>
                  <Std>14:28</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
        </GridRow>
        <hr />
        <GridRow>
          <div>
            <h4>Late Shift - Monday to Thursday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>16:35</Std>
                  <Std>18:40</Std>
                  <Std>20:40</Std>
                  <Std>22:45</Std>
                  <Std>00:53</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
          <div>
            <h4>Late Shift - Friday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>14:45</Std>
                  <Std>17:20</Std>
                  <Std>19:20</Std>
                  <Std>21:25</Std>
                  <Std>23:03</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
        </GridRow>
        <hr />
        <h3>3 Shift Pattern</h3>
        <GridRow>
          <div>
            <h4>Day Shift - Monday to Thursday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>06:45</Std>
                  <Std>09:20</Std>
                  <Std>12:05</Std>
                  <Std>15:20</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
          <div>
            <h4>Day Shift - Friday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>06:45</Std>
                  <Std>09:05</Std>
                  <Std>11:35</Std>
                  <Std>14:30</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
        </GridRow>
        <hr />
        <GridRow>
          <div>
            <h4>Late Shift - Monday to Thursday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>15:15</Std>
                  <Std>17:05</Std>
                  <Std>19:05</Std>
                  <Std>21:10</Std>
                  <Std>23:05</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
          <div>
            <h4>Late Shift - Friday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>14:25</Std>
                  <Std>17:05</Std>
                  <Std>19:05</Std>
                  <Std>21:10</Std>
                  <Std>23:05</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
        </GridRow>
        <hr />
        <GridRow>
          <div>
            <h4>Night Shift - Sunday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>20:55</Std>
                  <Std>00:00</Std>
                  <Std>02:15</Std>
                  <Std>04:40</Std>
                  <Std>06:50</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
          <div>
            <h4>Night Shift - Monday to Thursday</h4>
            <Table marginBottom>
              <thead>
                <Str>
                  <Sth>Start</Sth>
                  <Sth>Break</Sth>
                  <Sth>Dinner</Sth>
                  <Sth>Break</Sth>
                  <Sth>Finish</Sth>
                </Str>
              </thead>
              <tbody>
                <Str>
                  <Std>23:00</Std>
                  <Std>11:10</Std>
                  <Std>03:05</Std>
                  <Std>05:05</Std>
                  <Std>06:50</Std>
                </Str>
                <Str>
                  <Std></Std>
                  <Std>15 Minutes</Std>
                  <Std>25 Minutes</Std>
                  <Std>10 Minutes</Std>
                  <Std></Std>
                </Str>
              </tbody>
            </Table>
          </div>
        </GridRow>
        <hr />
      </TableContainer>
    </>
  )
}

export default ShiftPattern
